<template>
  <validation-observer v-if="order" ref="observer" tag="div">
    <v-card
      style="position: sticky; top: 110px; z-index: 101; gap: 0px"
      class="d-flex flex-column mb-4"
      rounded="lg"
      outlined
    >
      <template v-if="decisionMode && order">
        <order-approval-workflow-stepper :order="order" />
        <v-divider />
      </template>
      <v-tabs v-model="tab">
        <v-tab v-for="item of orderTabs" :key="item.id">
          {{ item.text }}
        </v-tab>
      </v-tabs>
    </v-card>

    <v-container
      class="pt-0 px-0 d-flex"
      fluid
      style="gap: 1px; margin-top: 1px; gap: 15px"
      :style="{
        paddingBottom: decisionMode ? '80px' : '0px'
      }"
    >
      <v-row dense v-if="currentTabId === 'order-details'">
        <v-col :lg="7" :md="6">
          <order-details
            v-if="currentTabId === 'order-details'"
            v-model="order"
            :selected-vendor="selectedVendor"
            :is-manual-creation-mode="isManualCreationMode"
            :is-in-approval-round="isInApprovalRound"
            read-only-mode
            :system-tags="account.systemTags"
            height="auto"
          />
        </v-col>
        <v-col :lg="5" :md="6">
          <order-document
            :order="order"
            v-if="currentTabId === 'order-details' && order"
            height="auto"
          />
        </v-col>
      </v-row>

      <order-items
        v-if="currentTabId === 'order-items'"
        v-model="order"
        :catalog-items="catalogItems"
        :vendor-vat="vendorVat"
        read-only-mode
        class="flex-grow-1"
      />
      <order-approvals
        v-if="currentTabId === 'approval-workflow'"
        v-model="order"
        :account-workflows="accountWorkflows"
        :approvers="approvers"
        :current-user="currentUser"
        readonly
        class="flex-grow-1"
      />
    </v-container>

    <v-footer
      v-if="decisionMode"
      fixed
      class="elevation-2"
      style="column-gap: 10px"
      :height="80"
    >
      <cz-button
        :title="$t('customer.orders.approveOrder')"
        color="positive"
        dark
        :icon-src="mdiCheckAll"
        :icon-size="20"
        large
        rounded
        :min-width="180"
        @click="onApproveOrder"
      />
      <v-spacer />
      <div class="d-flex align-center" style="column-gap: 15px">
        <template>
          <cz-button
            :title="$t('customer.orders.returnOrder')"
            color="primary darken-3"
            x-large
            rounded
            text
            :icon-src="mdiUndo"
            :width="160"
            @click="onReturnOrder"
          />
          <v-divider class="my-2" vertical />
          <cz-button
            :title="$t('customer.orders.rejectOrder')"
            color="negative"
            x-large
            text
            rounded
            :width="160"
            :icon-src="mdiClose"
            @click="onRejectOrder"
          />
        </template>
      </div>
    </v-footer>

    <submission-decision-dialog
      v-model="decisionDialog.show"
      v-bind="decisionDialog"
      @close="decisionDialog.show = false"
      @on-submit="onSubmitDecision"
    />
  </validation-observer>
</template>

<script>
import { getUserRoles, createOrderApprovalDecision } from '@/core/api';
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { CzButton } from '@/components';
import cloneDeep from 'lodash.clonedeep';
import { typesMixin } from '@/shared/mixins';
import { mdiUndo, mdiCancel, mdiClose, mdiCheckAll, mdiCheck } from '@mdi/js';
import OrderStatus from '@/shared/types/OrderStatus';
import { extractRolesFromWorkflow } from '@/shared/services/workflow.service';
import groupBy from 'lodash.groupby';
import SubmissionDecisionDialog from '../components/SubmissionDecisionDialog.vue';
import { DecisionType } from '@/domain/customer/components/SubmissionDecisionDialog.vue';
import OrderApprovalWorkflowStepper from '../components/OrderApprovalWorkflowStepper.vue';

export default {
  name: 'OrderDecisionPage',
  components: {
    OrderDetails: () => import('../components/order/OrderDetails.vue'),
    OrderItems: () => import('../components/order/OrderItems.vue'),
    OrderApprovals: () => import('../components/order/OrderApprovals.vue'),
    OrderDocument: () => import('../components/order/OrderDocument.vue'),
    CzButton,
    SubmissionDecisionDialog,
    OrderApprovalWorkflowStepper
  },
  props: {
    id: {
      type: String,
      required: true
    },
    value: {
      type: Object
    }
  },
  mixins: [typesMixin],
  created() {
    this.order = cloneDeep(this.value);
    this.selectedVendor = this.order.vendor;
  },
  computed: {
    ...mapFields('auth', ['account', 'accountId', 'currentUser']),
    ...mapFields('customer', [
      'currentOrdersTabId',
      'accountWorkflows',
      'catalogItems'
    ]),
    decisionMode() {
      return this.$route.name === 'order-decision';
    },
    isManualCreationMode() {
      return this.order?.creationType === 'manual';
    },
    isInApprovalRound() {
      return this.order?.status === OrderStatus.PENDING_APPROVAL;
    },

    currentTabId() {
      return this.orderTabs[this.tab]?.id;
    },
    orderTabs() {
      if (this.isManualCreationMode) {
        const tabs = [
          {
            text: this.$t('customer.orders.orderGeneralDetails'),
            id: 'order-details'
          },
          {
            text: this.$t('customer.orders.orderItems'),
            id: 'order-items'
          },
          {
            text: this.$t('customer.orders.orderApprovalWorkflow'),
            id: 'approval-workflow'
          }
        ];

        if (!this.order?.hasOwnProperty('orderItems')) {
          tabs.splice(1, 1);
        }

        return tabs;
      } else {
        return [
          {
            text: this.$t('customer.orders.orderGeneralDetails'),
            id: 'order-details'
          },
          {
            text: this.$t('customer.orders.orderApprovalWorkflow'),
            id: 'approval-workflow'
          }
        ];
      }
    }
  },
  data() {
    return {
      mdiCancel,
      mdiClose,
      mdiCheck,
      mdiUndo,
      mdiCheckAll,
      order: null,
      loading: false,
      saving: false,
      cancelling: false,
      closing: false,
      tab: 0,
      approvers: null,
      decisionDialog: {
        show: false,
        decisionType: '',
        objectType: 'order',
        loading: false
      }
    };
  },
  methods: {
    ...mapActions('ui', ['showSuccessToastMessage', 'showErrorToastMessage']),
    ...mapActions('customer', ['loadAccountWorkflows', 'loadCatalogItems']),
    async loadOrderApprovers() {
      if (this.order?.authorizerUser) {
        this.approvers = [this.order.authorizerUser];
      } else {
        const roles = extractRolesFromWorkflow(this.accountWorkflows);
        const { data: userRoles } = await getUserRoles({
          accountId: this.accountId,
          role: { $in: roles },
          $populate: ['user']
        });

        this.approvers = groupBy(userRoles, 'role');
      }
    },
    onApproveOrder() {
      this.decisionDialog.decisionType = DecisionType.APPROVE;
      this.decisionDialog.show = true;
    },
    onRejectOrder() {
      this.decisionDialog.decisionType = DecisionType.REJECT;
      this.decisionDialog.show = true;
    },
    onReturnOrder() {
      this.decisionDialog.decisionType = DecisionType.RETURN;
      this.decisionDialog.show = true;
    },
    async onSubmitDecision(decisionType, form) {
      try {
        this.decisionDialog.loading = true;
        await createOrderApprovalDecision({
          orderId: this.order._id,
          accountId: this.accountId,
          decision: this.getOrderStatusFromDecisionType(decisionType),
          returnType: decisionType === DecisionType.RETURN ? form.type : '',
          rejectType:
            decisionType === DecisionType.REJECT ? form.type : undefined,
          note: form.note || ''
        });

        this.decisionDialog.show = false;
        this.$router.replace({
          name: 'customer-orders'
        });
      } finally {
        this.decisionDialog.loading = false;
      }
    },
    getOrderStatusFromDecisionType(decisionType) {
      switch (decisionType) {
        case DecisionType.APPROVE:
          return 'approved';
        case DecisionType.RETURN:
          return OrderStatus.RETURNED;
        case DecisionType.REJECT:
          return OrderStatus.REJECTED;
      }
    }
  },
  watch: {
    order: {
      handler(value) {
        if (value) {
          this.loadOrderApprovers();
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.container-height {
  // max-height: calc(100vh - 400px);
  overflow-y: auto;
}

.doc-height {
  overflow: auto;
  // aspect-ratio: 1.25 / 1.414 !important;
  // scale: 1.2;
}
</style>
