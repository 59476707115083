<template>
  <v-stepper
    :value="currentStepIndex"
    color="primary"
    flat
    style="border-radius: 8px"
  >
    <v-stepper-header>
      <template v-for="(item, index) in steps">
        <v-stepper-step
          :key="item.id"
          :complete="item.completed"
          :step="index + 1"
          :complete-icon="mdiCheck"
          :color="item.completed ? 'primary darken-1' : 'primary'"
        >
          {{ item.text }}
        </v-stepper-step>
        <v-divider
          v-if="index < steps.length - 1"
          :key="`${item.id}_divider`"
        />
      </template>
    </v-stepper-header>
  </v-stepper>
</template>

<script>
import OrderStatus from '@/shared/types/OrderStatus';
import { mdiCheck } from '@mdi/js';
export default {
  name: 'OrderApprovalWorkflowStepper',
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  computed: {
    currentStepIndex() {
      const index = this.steps.findIndex((item) => item.isCurrent === true);
      return index + 1;
    },
    steps() {
      const steps = [];

      if (!this.order) {
        return steps;
      }

      steps.push({
        id: 'step1',
        text: this.$t('customer.orders.orderApprovalSteps.step1'),
        isCurrent: false,
        completed: true
      });

      steps.push({
        id: 'step2',
        text: this.$t('customer.orders.orderApprovalSteps.step2'),
        isCurrent: [
          OrderStatus.NEW,
          OrderStatus.NEW_PREDICTED,
          OrderStatus.RETURNED
        ].includes(this.order.status),
        completed: [
          OrderStatus.PENDING_APPROVAL,
          OrderStatus.CLOSED,
          OrderStatus.OPEN,
          OrderStatus.OPEN_PENDING_VENDOR_REG
        ].includes(this.order.status)
      });

      for (const step of this.order.orderApprovalWorkflowSteps) {
        steps.push({
          id: step._id,
          text: step.stepTitle,
          isCurrent:
            this.order.currentWorkflowApprovalStepId ===
            step.accountWorkflowStepId,
          completed: this.order.completedWorkflowStepIds?.includes(
            step.accountWorkflowStepId
          )
        });
      }

      return steps;
    }
  },
  data() {
    return {
      mdiCheck
    };
  }
};
</script>

<style lang="scss" scoped></style>
