<template>
  <validation-observer ref="observer" v-slot="{ invalid }" tag="div">
    <cz-dialog
      v-on="$listeners"
      v-bind="$attrs"
      :width="550"
      :max-width="'90%'"
      :title="title"
      show-actions
    >
      <v-card flat :loading="loading" class="mt-2">
        <v-card-text>
          <v-form class="position-relative" :disabled="loading">
            <cz-form-field
              :label="$t('customer.vendorSubmissions.returnType')"
              required
              v-if="isDecisionReturn"
            >
              <validation-provider
                rules="required"
                :name="$t('customer.vendorSubmissions.returnType')"
                v-slot="{ errors }"
              >
                <cz-autocomplete
                  v-model="form.type"
                  :error-messages="errors"
                  :placeholder="
                    $t('customer.vendorSubmissions.returnTypeSelect')
                  "
                  :items="returnTypeItems"
                />
              </validation-provider>
            </cz-form-field>
            <cz-form-field
              :label="$t('customer.vendorSubmissions.rejectType')"
              required
              v-if="isDecisionReject"
            >
              <validation-provider
                rules="required"
                :name="$t('customer.vendorSubmissions.rejectType')"
                v-slot="{ errors }"
              >
                <cz-autocomplete
                  v-model="form.type"
                  :error-messages="errors"
                  :placeholder="
                    $t('customer.vendorSubmissions.rejectTypeSelect')
                  "
                  :items="rejectTypeItems"
                />
              </validation-provider>
            </cz-form-field>
            <cz-form-field :label="$t('customer.vendorSubmissions.note')">
              <validation-provider
                :rules="form.type === 'other' ? 'required' : ''"
                :name="$t('customer.vendorSubmissions.rejectType')"
                v-slot="{ errors }"
              >
                <cz-input
                  v-model="form.note"
                  dense
                  multi-line
                  no-resize
                  :error-messages="errors"
                />
              </validation-provider>
            </cz-form-field>
          </v-form>
        </v-card-text>
      </v-card>

      <template #actions>
        <div class="d-flex justify-center full-width">
          <cz-button
            :title="actionButtonTitle"
            rounded
            :color="titleColor"
            large
            :dark="!invalid"
            :width="150"
            :disabled="invalid"
            :loading="loading"
            @click="$emit('on-submit', decisionType, form)"
          />
        </div>
      </template>
    </cz-dialog>
  </validation-observer>
</template>

<script>
export const DecisionType = {
  APPROVE: 'approve',
  REJECT: 'reject',
  RETURN: 'return'
};

Object.freeze(DecisionType);

import {
  CzDialog,
  CzFormField,
  CzInput,
  CzAutocomplete,
  CzButton
} from '@/components';
export default {
  name: 'SubmissionDecisionDialog',
  components: {
    CzDialog,
    CzFormField,
    CzInput,
    CzAutocomplete,
    CzButton
  },
  props: {
    // can be order, order-submission
    objectType: {
      type: String
    },
    // Can be approve, reject, return
    decisionType: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    title() {
      if (this.objectType === 'order-submission') {
        switch (this.decisionType) {
          case DecisionType.APPROVE:
            return this.$t(
              'customer.vendorSubmissions.decisionActionButtons.approve'
            );
          case DecisionType.REJECT:
            return this.$t(
              'customer.vendorSubmissions.decisionActionButtons.reject'
            );
          case DecisionType.RETURN:
            return this.$t(
              'customer.vendorSubmissions.decisionActionButtons.return'
            );
        }
      } else {
        switch (this.decisionType) {
          case DecisionType.APPROVE:
            return this.$t('customer.orders.decisionActionButtons.approve');
          case DecisionType.REJECT:
            return this.$t('customer.orders.decisionActionButtons.reject');
          case DecisionType.RETURN:
            return this.$t('customer.orders.decisionActionButtons.return');
        }
      }
    },
    actionButtonTitle() {
      if (this.objectType === 'order-submission') {
        switch (this.decisionType) {
          case DecisionType.APPROVE:
            return this.$t('customer.vendorSubmissions.approveActionTitle');
          case DecisionType.REJECT:
            return this.$t('customer.vendorSubmissions.rejectActionTitle');
          case DecisionType.RETURN:
            return this.$t('customer.vendorSubmissions.returnActionTitle');
        }
      } else if (this.objectType === 'order') {
        switch (this.decisionType) {
          case DecisionType.APPROVE:
            return this.$t('customer.orders.approveOrderActionTitle');
          case DecisionType.REJECT:
            return this.$t('customer.orders.rejectOrderActionTitle');
          case DecisionType.RETURN:
            return this.$t('customer.orders.returnOrderActionTitle');
        }
      }
    },
    titleColor() {
      switch (this.decisionType) {
        case DecisionType.APPROVE:
          return 'primary';
        case DecisionType.REJECT:
          return 'primary';
        case DecisionType.RETURN:
          return 'primary';
      }
    },
    isDecisionReturn() {
      return this.decisionType === DecisionType.RETURN;
    },
    isDecisionReject() {
      return this.decisionType === DecisionType.REJECT;
    },
    isDecisionApprove() {
      return this.decisionType === DecisionType.APPROVE;
    },
    returnTypeItems() {
      if (this.objectType === 'order-submission') {
        return [
          {
            text: this.$t(
              'customer.vendorSubmissions.returnTypes.accountingBookCertificateExpired'
            ),
            value: 'accounting_book_cert_expired'
          },
          {
            text: this.$t(
              'customer.vendorSubmissions.returnTypes.deliveryCertMissing'
            ),
            value: 'delivery_cert_missing'
          },
          {
            text: this.$t(
              'customer.vendorSubmissions.returnTypes.executionReportMissing'
            ),
            value: 'execution_report_missing'
          },
          {
            text: this.$t(
              'customer.vendorSubmissions.returnTypes.referenceMissing'
            ),
            value: 'references_missing'
          },
          {
            text: this.$t(
              'customer.vendorSubmissions.returnTypes.duplicateSubmission'
            ),
            value: 'duplicate_submission'
          },
          {
            text: this.$t('customer.vendorSubmissions.returnTypes.other'),
            value: 'other'
          }
        ];
      } else if (this.objectType === 'order') {
        return [
          {
            text: this.$t('customer.orders.returnTypes.invalid_quantity'),
            value: 'invalid_quantity'
          },
          {
            text: this.$t('customer.orders.returnTypes.invalid_price'),
            value: 'invalid_price'
          },
          {
            text: this.$t('customer.orders.returnTypes.other'),
            value: 'other'
          }
        ];
      }
    },
    rejectTypeItems() {
      if (this.objectType === 'order-submission') {
        return [
          {
            text: this.$t(
              'customer.vendorSubmissions.rejectTypes.invoiceUnmatched'
            ),
            value: 'unmatched_invoice'
          },
          {
            text: this.$t('customer.vendorSubmissions.rejectTypes.invalidDate'),
            value: 'invalid_date'
          },
          {
            text: this.$t(
              'customer.vendorSubmissions.rejectTypes.invalidQuantity'
            ),
            value: 'invalid_amount'
          },
          {
            text: this.$t(
              'customer.vendorSubmissions.rejectTypes.invalidAmount'
            ),
            value: 'invalid_amount'
          }
        ];
      } else if (this.objectType === 'order') {
        return [
          {
            text: this.$t('customer.orders.rejectTypes.duplicate_order'),
            value: 'duplicate_order'
          },
          {
            text: this.$t('customer.orders.rejectTypes.budget_exceed'),
            value: 'budget_exceed'
          },
          {
            text: this.$t('customer.orders.rejectTypes.project_cancelled'),
            value: 'project_cancelled'
          },
          {
            text: this.$t('customer.orders.rejectTypes.invalid_vendor'),
            value: 'invalid_vendor'
          },
          {
            text: this.$t('customer.orders.rejectTypes.other'),
            value: 'other'
          }
        ];
      }

      return [];
    }
  },
  data() {
    return {
      form: {
        type: '',
        note: ''
      }
    };
  }
};
</script>

<style lang="scss" scoped></style>
